import Section12 from "../mobile-components/Section12";
import Section4Card from "../mobile-components/Section4Card";
import Section2 from "../mobile-components/Section2";
import MobileHeader from "../mobile-components/Mobileheader";
import { MobileFooterHome } from "../mobile-components/Footer";
import { CardGrid } from "../mobile-components/section";
import { FeatureComponent } from "../mobile-components/faeture-component";
// import TiltImage from "/background-image.png"

const Mobile = () => {
  return (
    <div className="bg-color-white w-full overflow-hidden text-center text-lg text-color-white font-inter">
      <MobileHeader />
      <Section2 />
      <div className="flex flex-row items-center mt-10 text-11xl text-brand-color-primary">
        <div className="md:w-1/5 hidden md:block" />
        <div className="w-full md:w-3/5">
          <b className="relative inline-block pl-5 pr-5">
            Our AI-powered suite of tools is designed to revolutionize the way
            you manage your hotel
          </b>
          <div className="relative px-1 text-lg pb-10 leading-[28px] font-medium text-gray-100 inline-block pt-5">
            Enhancing guest experiences and optimizing your operations like
            never before
          </div>
        </div>
        <div className="md:w-1/5 hidden md:block" />
      </div>
      <Section4Card />
      <div className="mt-10 flex flex-col">
        <div className="relative top-14 md:top-32 md:text-5xl flex m-auto justify-center text-center align-middle">
          <div className="md:w-1/5 hidden md:block" />
          <div className="w-full md:w-3/5">
            <span className="relative text-white leading-3 font-inter text-sm md:text-9xl md:leading-8 inline-block pl-5 pr-5">
              Where modern innovation and thoughtful guest experiences come
              together to create a transformative hospitality journey
            </span>
          </div>
          <div className="md:w-1/5 hidden md:block" />
        </div>
        <img src="/background-tilt.png" className="w-full h-full" />
      </div>
      {/* <div className="flex skewY-5 text-center justify-center align-middle mt-10 pt-[70px] pb-[70px] [background:linear-gradient(264.48deg,_#79f1a4,_#5bacf2)] shadow-[0px_5px_22.58px_rgba(50,_174,_137,_0.25)]">
        <div className="md:w-1/5 hidden md:block" />
        <div className="w-full md:w-3/5">
          <b className="relative inline-block pl-5 pr-5">
            Where modern innovation and thoughtful guest experiences come
            together to create a transformative hospitality journey
          </b>
        </div>
        <div className="md:w-1/5 hidden md:block" />
      </div> */}

      {/* background image on text */}
      <div className="tilt"></div>

      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-full"
        height="262"
        viewBox="0 0 1650 262"
        fill="none"
      >
        <path d="M0 0H1440V222.13L0 262V0Z" fill="#C4C4C4" />
        <path
          d="M0 0H1440V222.13L0 262V0Z"
          width="100%"
          fill="url(#paint0_linear_305_1450)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_305_1450"
            x1="-100"
            y1="262"
            x2="1650.99"
            y2="587.331"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5BACF2" />
            <stop offset="1" stop-color="#169571" />
          </linearGradient>
        </defs>
      </svg> */}

      <CardGrid
        order="last"
        image="/right@2x.png"
        title="Step into a world of limitless possibilities with our "
        subtitle="AI-powered Channel Manager"
        description="Connect effortlessly to global travel platforms, optimize rates with
        AI-driven Smart Price, and attract more bookings and revenue. Elevate
        guest experiences with data-driven Upselling."
        checklist={[
          "Smart Price Optimization",
          "Seamless Connectivity",
          "Centralized Inventory Management",
        ]}
      />
      <CardGrid
        image="/ai-powered-channel-manager-3@2x.png"
        title="Increase direct bookings, improve the guest experience, and reduce your costs through "
        subtitle="Booking Engine"
        description="Picture a seamless, hassle-free booking process for your guests,
        right from your private website. From cozy rooms to enchanting
        suites, guests will find their perfect haven with ease, empowering
        them to plan their dream getaways with just a few clicks."
        checklist={[
          "Easy mobile bookings",
          "Competitor and rate parity insights",
          "Drive bookings from social media",
        ]}
      />
      <CardGrid
        order="last"
        image="/ai-powered-channel-manager-4@2x.png"
        title="Use of AI to improve the way businesses interact with "
        subtitle="Reviews"
        description="Our AI Review Engagement fosters connections, addresses concerns
        promptly, and builds a loyal online community with a stellar
        reputation."
        checklist={[
          "Automate review collection process",
          "Analyzing review data",
          "Personalize review response process",
        ]}
      />
      <CardGrid
        image="/photo6@2x.png"
        title="Simplify & optimize hotel operations with our all-in-one "
        subtitle="Property Management System"
        description="Seamless guest management, surpassing preferences, and harmonious
        task management create delightful experiences and cherished
        memories."
        checklist={[
          "Reservations and room bookings",
          "Guest relations",
          "Marketing",
        ]}
      />
      <FeatureComponent />
      <Section12 />
      <MobileFooterHome />
    </div>
  );
};

export default Mobile;
