import Socials from "./Socials";
import React from "react";
import ReactGA from "react-ga4";

const MobileFooterHome = () => {
  const buttonClick = (title) => {
    console.log(`Footer clicked ${title}`);
    ReactGA.event({
      category: "button",
      action: `button clicked ${title}`,
      label: `${title} footer nav-button clicked`,
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 bg-gray-200 pb-10 pt-10 pl-5 mt-8 overflow-hidden box-border items-start justify-between text-left text-smi text-color-gray-4 font-inter">
      <div className="flex flex-col items-start justify-start gap-[20px] md:pl-10">
        <img className="" alt="" src="/logo.svg" />
        <div className="flex text-start justify-start w-60  leading-[20px]">
          <p className="text-justify">
            Our goal at Cloudsiesta is to elevating Hospitality Experiences,
            Streamlining Operations, and Maximizing Revenue for Hotels
            Worldwide.
          </p>
        </div>
        <Socials
          linkedin="/linkedin1.svg"
          instagram="/instagram1.svg"
          facebook="/facebook1.svg"
          socialsPosition="unset"
        />
      </div>
      <div className="mt-10 shrink-0 grid grid-cols-2 md:grid-cols-4 gap-[10px] text-left text-smi text-color-white font-inter">
        <div className="flex flex-col items-start justify-start gap-[17px]">
          <div className="relative text-xl leading-[30px] font-medium text-steelblue">
            Services
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("Customers")}
          >
            Customers
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("Collaborators")}
          >
            Collaborators
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[17px]">
          <div className="relative text-xl leading-[30px] font-medium text-steelblue">
            Company
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("About Us")}
          >
            About Us
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("Our Team")}
          >
            Our Team
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[17px]">
          <div className="relative text-xl leading-[30px] font-medium text-steelblue">
            Support
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("FAQs")}
          >
            FAQs
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("Privacy Policy")}
          >
            Privacy Policy
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[17px]">
          <div className="relative text-xl leading-[30px] font-medium text-steelblue">
            Contact Us
          </div>
          <a
            target="_blank"
            className="text-white no-underline"
            href={`mailto:info@cloudsiesta.com`}
          >
            <div
              className="relative leading-[26px]"
              onClick={() => buttonClick("Email")}
            >
              Email: info@cloudsiesta.com
            </div>
          </a>
          <a
            target="_blank"
            className="text-white no-underline"
            href="https://wa.me/+971525336684"
            onClick={() => buttonClick("Phone")}
          >
            <div className="relative leading-[26px]">Call: +971 525336684</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export { MobileFooterHome };
