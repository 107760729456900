import MobileHeader from "../mobile-components/Mobileheader";
import { MobileFooterHome } from "../mobile-components/Footer";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const priceList = [
  {
    title: "Starter Boost",
    desc: "Seamless connectivity, real-time updates, and multilingual support for enhanced hotel performance",
    price: "$40",
    features: [
      "Access more than 200+ channel manager",
      "Fast, reliable integration with PMS system or use our PMS",
      "AI Powered Revenue Management",
      "Multilingual Support",
    ],
  },
  {
    title: "🌟 Ultimate Value 🌟",
    desc: "Maximize Revenue: Smart Price Optimization, Centralized Inventory, Data-Driven Insights",
    selected: true,
    price: "$80",
    features: [
      "All features of Starter Boost",
      "Seamless Connectivity with all available OTA's and GDS's",
      "AI Powered Review Engagement",
      "AI Price Optimization based on market demand and competitor pricing",
      "Build in CRM to manage your guest relationships",
      "Booking Engine",
      "24/7 support",
    ],
  },
  {
    title: "Powerhouse Package",
    desc: "AI-powered insights, advanced features, and extensive channel connectivity for unmatched hotel success",
    price: "$499",
    features: [
      "All features of Ultimate Value",
      "AI Powered predictive future demand and pricing",
      "AI-driven personalization, Social Media Listening",
      "Competitor and rate parity insights and Data-Driven Decision Making",
      "Advance AI based Report and Analytics",
      "Premium Support",
    ],
  },
];

const SignupMobile = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/signup",
      title: "Signup Page",
    });
  }, []);

  const buttonClick = () => {
    ReactGA.event({
      category: "button",
      action: "Subscribe button clicked",
      label: "Subscribe button",
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });
  };

  return (
    <>
      <MobileHeader />
      <section
        className="bg-white dark:bg-gray-900"
        style={{ fontFamily: "unset" }}
      >
        <div className="md:mt-2 py-2 px-4 mx-auto max-w-screen-xl md:py-2 lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-2 md:mb-10 lg:mb-12">
            <h2 className="text-black dark:text-white font-inter">
              Join our waitlist and get access to Cloudsiesta
              <br /> for discounted early-bird prices
            </h2>
            <div className="w-full">
              <form action="https://submit-form.com/Ot0K2A2X">
                <div className="flex gap-4 flex-col md:flex-row px-10 items-center justify-center mb-2">
                  <input
                    type="text"
                    class="block h-10 border border-grey-light w-full p-3 rounded font-inter"
                    name="name"
                    placeholder="Full Name (Optional)"
                  />
                  <input
                    type="text"
                    class="block h-10 border border-grey-light w-full p-3 mr-1 rounded font-inter"
                    name="company_name"
                    placeholder="Company Name (Optional)"
                  />
                </div>
                <div className="flex gap-5 flex-col md:flex-row px-10 items-center justify-center mb-2">
                  <input
                    type="email"
                    class="block h-10 border border-grey-light w-full p-3 rounded font-inter"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="px-6 md:px-10 md:mr-4 flex flex-center">
                  <button
                    type="submit"
                    className="p-5 w-full font-inter rounded-l-none hover:bg-indigo-600 bg-[#10375C] rounded text-base font-medium leading-none text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
                    onClick={() => buttonClick()}
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            {priceList.map((price) => {
              return (
                <div
                  style={price.selected ? { border: "1px solid #000" } : {}}
                  key={price.title}
                  className={`flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white ${
                    price.selected ? "relative" : ""
                  }`}
                >
                  {price.selected && (
                    <div className="absolute top-0 right-0 -mt-4 -mr-8">
                      <div className="bg-blue-500 text-white text-lg py-2 px-4 rounded-tl-lg transform rotate-45">
                        Popular
                      </div>
                    </div>
                  )}
                  <h3 className="mb-4 text-2xl font-semibold font-inter">
                    {price.title}
                  </h3>
                  <p className="font-light font-inter text-gray-500 sm:text-lg dark:text-gray-400">
                    {price.desc}
                  </p>
                  <div className="flex justify-center items-baseline my-8">
                    <span className="mr-2 text-5xl font-extrabold font-inter">
                      {price.price}
                    </span>
                    <span className="text-gray-500 dark:text-gray-400 font-inter">
                      /month
                    </span>
                  </div>

                  <ul role="list" className="mb-8 space-y-4 text-left">
                    {price.features.map((feature) => {
                      return (
                        <li
                          className="flex items-center space-x-3"
                          key={feature}
                        >
                          <svg
                            className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="font-inter">{feature}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <MobileFooterHome />
    </>
  );
};

const Signup = () => {
  return <SignupMobile />;
};

export default Signup;
