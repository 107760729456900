import React from "react";
import ReactGA from "react-ga4";

const FeatureComponent = () => {
  const buttonClick = () => {
    console.log("Button clicked Request a demo bottom")
    ReactGA.event({
      category: "button",
      action: "Request a demo button clicked",
      label: "Request a demo button bottom",
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 px-4">
        <div className="bg-cornflowerblue md:order-last flex md:grid-cols-2 pt-100 box-border items-start justify-start text-left">
          <div className="flex flex-col px-10 pb-10 pt-5 box-border items-start justify-start gap-[11px]">
            <div className="relative leading-[30px] font-medium">
              Why Choose cloudsiesta
            </div>
            <b className="relative text-[32px] inline-block shrink-0">
              Next-Gen AI-Powered Hospitality Suite
            </b>
            <div className="relative text-base leading-[30px] text-justify inline-block shrink-0">
              It is specifically designed to elevate guest satisfaction to new
              heights, making it the ideal choice for accommodation providers
              looking to excel in the competitive hospitality industry.
            </div>
          </div>
        </div>
        <div className="flex mt-10 flex-col items-center justify-center gap-[13px] text-left text-base text-darkslategray">
          <div className="flex flex-col">
            <div className="flex flex-row gap-3">
              <img className="pl-2" alt="" src="/vector1.svg" />
              <div className="flex pt-1 flex-row items-center font-bold justify-center leading-[103.69%] gap-[11px] text-3xl text-brand-color-primary">
                Be at the top of distribution channels
              </div>
            </div>
            <div className="text-[#384356] inline-block pl-12">
              By integrating with top distribution channels, including the GDS
              we will make sure you are at the top of list.
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row gap-3">
              <img className="pl-2" alt="" src="/vector1.svg" />
              <div className="flex pt-1 flex-row items-center font-bold justify-center leading-[103.69%] gap-[11px] text-3xl text-brand-color-primary">
                Everything in one place
              </div>
            </div>
            <div className="text-[#384356] inline-block pl-12">
              Save time and effort with a single place to manage your
              distribution, reservations and payments.
            </div>
          </div>
          <div className="flex flex-col mt-5 justify-center text-center items-center">
            <a className="no-underline" href="/signup">
              <div className="flex flex-col justify-center align-middle items-center text-center">
                <button
                  onClick={() => buttonClick()}
                  className="p-5 text-white text-xl w-full font-medium flex items-center text-center justify-center shrink-0 cursor-pointer [border:none] bg-[transparent] relative rounded-41xl [background:linear-gradient(264.48deg,_#79f1a4,_#5bacf2)] shadow-[0px_5px_22.58px_rgba(50,_174,_137,_0.25)]"
                >
                  Request a Demo
                </button>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export { FeatureComponent };
