const CardGrid = ({
  image,
  title,
  subtitle,
  description,
  checklist,
  order,
}) => {
  return (
    <div className="flex-col-reverse grid grid-cols-1 md:grid-cols-2 items-start my-7 justify-start text-left text-11xl text-brand-color-primary">
      <div
        className={`flex items-start pt-5 mr-5 ml-5 ${
          order == "last" ? "md:order-last" : ""
        }`}
      >
        <img className="max-w-full h-auto object-cover" alt="" src={image} />
      </div>
      <div className="flex flex-col items-left justify-center px-3 pt-5 gap-[21px]">
        <div className="px-4">
          <span>{title} </span>
          <span className="font-medium">{subtitle}</span>
        </div>
        <div className="px-4 text-lg leading-[28px] text-darkslategray text-justify inline-block shrink-0">
          {description}
        </div>
        <div className="px-4 text-3xl flex gap-2 flex-col">
          {checklist?.map((item) => {
            return (
              <div className="flex gap-5" key={item}>
                <img className="" alt="" src="/vector.svg" />
                <b className="leading-[103.69%]">{item}</b>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { CardGrid };
