import ComponentCard from "./ComponentCard";

const Section4Card = () => {
  return (
    <div id="platformfeatures" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pl-10 pr-10">
      <ComponentCard
        photo="/photo3@2x.png"
        title="AI Powered Channel Manager"
        subtitle="Smartly connect to top OTAs and GDS for optimized rates, boosting bookings and revenue worldwide. Unleash the true potential of your business as AI powers your success!"
      />
      <ComponentCard
        photo="/photo2@2x.png"
        title="Booking Engine"
        subtitle="Supercharge direct bookings! Our AI powered Booking Engine ensures seamless, personalized experiences, maximizing profits for your property. Be steps ahead of the competition!"
      />
      <ComponentCard
        photo="/photo5@2x.png"
        title="AI Powered Review Engagement"
        subtitle="Prioritize guest satisfaction with AI powered Review Engagement. Stay on top of feedback, respond promptly, and build a stellar online reputation for exceptional service."
      />
      <ComponentCard
        photo="/photo1@2x.png"
        title="Built-In PMS System"
        subtitle="Streamline hotel operations with our all-in-one PMS. Integrated CRM ensures personalized experiences, boosting productivity & guest satisfaction."
      />
      <ComponentCard
        photo="/photo4@2x.png"
        title="AI-Powered Insights"
        subtitle=" AI-driven personalization, Social Media Listening, Sustainability Metric Tracking, and Local Experience Integration create a transformative guest experience."
      />
      <ComponentCard
        photo="/photo6@2x.png"
        title="Hospitality Standard Features"
        subtitle="Provide exceptional guest experiences, create lasting connections, and promote responsible practices, setting them apart as leaders in the modern accommodation industry."
      />
    </div>
  );
};

export default Section4Card;
